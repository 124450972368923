import React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import DataFramesIcon from "../../icons/param-mapping/DataFramesIcon";
import AircraftInfoIcon from "../../icons/aircraft-info/AircraftInfoIcon";
import AircraftIcon from "../../icons/aircraft-info/AircraftIcon";
import FleetIcon from "../../icons/aircraft-info/FleetIcon";
import { Tooltip } from "@mui/material";
import RecorderConfigsIcon from "../../icons/recorderconfigs/RecorderConfigsIcon";

function AircraftInfoNav({ iconColor, routeTo, showToolTip }) {
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <div>
            <List
                sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <Tooltip
                    title={showToolTip ? "Aircraft Info" : ""}
                    arrow={true}
                >
                    <ListItemButton onClick={handleClick}>
                        <ListItemIcon>
                            <AircraftInfoIcon iconColor={iconColor} />
                        </ListItemIcon>
                        <ListItemText primary="Aircraft Info" />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </Tooltip>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <Link
                            style={{ textDecoration: "none", color: "inherit" }}
                            to="/dataframes"
                        >
                            <Tooltip
                                title={showToolTip ? "Data Frames" : ""}
                                arrow={true}
                            >
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <DataFramesIcon iconColor={iconColor} />
                                    </ListItemIcon>
                                    <ListItemText primary="Data Frames" />
                                </ListItemButton>
                            </Tooltip>
                        </Link>

                        <Link
                            style={{ textDecoration: "none", color: "inherit" }}
                            to="/fleets"
                        >
                            <Tooltip
                                title={showToolTip ? "Fleets" : ""}
                                arrow={true}
                            >
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <FleetIcon iconColor={iconColor} />
                                    </ListItemIcon>
                                    <ListItemText primary="Fleets" />
                                </ListItemButton>
                            </Tooltip>
                        </Link>

                        <Link
                            style={{ textDecoration: "none", color: "inherit" }}
                            to="/recorderconfigs"
                        >
                            <Tooltip
                                title={showToolTip ? "Recorder Configs" : ""}
                                arrow={true}
                            >
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <RecorderConfigsIcon
                                            iconColor={iconColor}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Recorder Configs" />
                                </ListItemButton>
                            </Tooltip>
                        </Link>

                        <Link
                            style={{ textDecoration: "none", color: "inherit" }}
                            to="/aircraft"
                        >
                            <Tooltip
                                title={showToolTip ? "Aircraft" : ""}
                                arrow={true}
                            >
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <AircraftIcon iconColor={iconColor} />
                                    </ListItemIcon>
                                    <ListItemText primary="Aircraft" />
                                </ListItemButton>
                            </Tooltip>
                        </Link>
                    </List>
                </Collapse>
            </List>
        </div>
    );
}

export default AircraftInfoNav;
