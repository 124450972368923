import { Grid } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import GddControls from "../../components/gdd-controls/GddControls";
import GddDialog from "../../components/gdd-dialog/GddDialog";
import GddDialogTitle from "../../components/gdd-dialog/GddDialogTitle";
import PackageIcon from "../../icons/param-mapping/PackageIcon";
import useAxios from "../../api/useAxios";

function PackageDetails(props) {
    const {
        open,
        setOpen,
        addOrEdit,
        values,
        setValues,
        setErrors,
        errors,
        handleInputChange,
        resetForm,
        validate,
        editMode,
        isAdmin,
    } = props;

    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const iconType = <PackageIcon iconSize="medium" />;

    const dlgTitle = (
        <GddDialogTitle
            title={editMode ? "Edit Package" : "Create New Package"}
            titleIcon={iconType}
        />
    );

    const autoCAircrafts = useRef();
    const autoCFlightData = useRef();
    const autoCReportData = useRef();
    const autoCSarData = useRef();
    const autoCEndPoint = useRef();

    const [airlines, setAirlines] = useState([]);
    const [aircrafts, setAircrafts] = useState([]);
    const [flightData, setFlightData] = useState([]);
    const [reportsData, setReportsData] = useState([]);
    const [sarData, setSarData] = useState([]);
    const [endPointsData, setEndPointsData] = useState([]);
    const [zipOptionsData, setZipOptionsData] = useState([]);

    useEffect(() => {
        createApiEndPoint(ENDPOINTS.AIRLINE)
            .fetchAll("getKeyValuePairs")
            .then((res) => {
                const airlinesToDisplay = res.data;
                let airlineOptions = airlinesToDisplay
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((item) => ({
                        id: item.id,
                        title: item.name,
                    }));
                setAirlines(airlineOptions);
            })
            .catch((err) => console.log(err));

        createApiEndPoint(ENDPOINTS.PACKAGES)
            .fetchAll("getZipOptions")
            .then((res) => {
                const zipOptionsToDisplay = res.data;
                let zipOptionsList = zipOptionsToDisplay
                    .sort((a, b) => (a.zipType > b.zipType ? 1 : -1))
                    .map((item) => ({
                        id: item.id,
                        title: item.zipType,
                    }));
                setZipOptionsData(zipOptionsList);
            })
            .catch((err) => console.log(err));
    }, []);

    const clearSelections = () => {
        const autoCRefs = [
            autoCAircrafts,
            autoCFlightData,
            autoCReportData,
            autoCSarData,
            autoCEndPoint,
        ];

        autoCRefs.forEach((item) => {
            const ele = item.current;
            if (ele) {
                const clearBtn = ele.getElementsByClassName(
                    "MuiAutocomplete-clearIndicator"
                )[0];
                if (clearBtn) {
                    clearBtn.click();
                }
            }
        });

        setErrors({
            ...errors,
            airlineId: "",
            aircraftSelection: "",
            flightDataSelection: "",
            reportDataSelection: "",
            sarDataSelection: "",
            endPointSelection: "",
            // zipOptionId: "",
        });
    };

    useEffect(() => {
        // Get the aircrafts for the selected airline

        if (values.airlineId > 0) {
            createApiEndPoint(ENDPOINTS.AIRCRAFT)
                .fetchAll(
                    `getKeyValuePairsByAirlineId?airlineId=${values.airlineId}`
                )
                .then((res) => {
                    const aircraftsData = res.data;
                    if (aircraftsData) {
                        let aircraftsDatalList = aircraftsData
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((item) => ({
                                id: item.id,
                                label: item.name,
                                value: item.name,
                                description: item.name,
                            }));
                        setAircrafts(aircraftsDatalList);
                    } else {
                        setAircrafts([]);
                    }
                    //    setXferTableRefresher(xferTableRefresher + 1);
                })
                .catch((err) => console.log(err));
        } else {
            setAircrafts([]);
        }

        if (values.airlineId > 0) {
            createApiEndPoint(ENDPOINTS.FLIGHTDATA)
                .fetchAll(
                    `getKeyValuePairsByAirline?airlineId=${values.airlineId}`
                )
                .then((res) => {
                    const flightDataToList = res.data;
                    if (flightDataToList) {
                        let flightDataToDisplay = flightDataToList
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((item) => ({
                                id: item.id,
                                label: item.name,
                                description: item.name,
                                value: item.name,
                            }));
                        setFlightData(flightDataToDisplay);
                    } else {
                        setFlightData([]);
                    }
                    values.flightDataSelection = null;
                })
                .catch((err) => console.log(err));
        } else {
            setFlightData([]);
        }

        if (values.airlineId > 0) {
            createApiEndPoint(ENDPOINTS.REPORTS)
                .fetchAll(
                    `getKeyValuePairsByAirline?airlineId=${values.airlineId}`
                )
                .then((res) => {
                    const reportsDataToList = res.data;
                    if (reportsDataToList) {
                        let reportsDataToDisplay = reportsDataToList
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((item) => ({
                                id: item.id,
                                label: item.name,
                                description: item.name,
                                value: item.name,
                            }));
                        setReportsData(reportsDataToDisplay);
                    } else {
                        setReportsData([]);
                    }
                })
                .catch((err) => console.log(err));
        } else {
            setReportsData([]);
        }
        
        if (values.airlineId > 0) {
            createApiEndPoint(ENDPOINTS.SARDATA)
                .fetchAll(
                    `getKeyValuePairsByAirline?airlineId=${values.airlineId}`
                )
                .then((res) => {
                    const sarDataToList = res.data;
                    if (sarDataToList) {
                        let sarDataToDisplay = sarDataToList
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((item) => ({
                                id: item.id,
                                label: item.name,
                                description: item.name,
                                value: item.name,
                            }));
                        setSarData(sarDataToDisplay);
                    } else {
                        setSarData([]);
                    }
                })
                .catch((err) => console.log(err));
        } else {
            setSarData([]);
        }

        // Get the defined end points for the selected airline
        if (values.airlineId > 0) {
            createApiEndPoint(ENDPOINTS.ENDPOINT_ASSIGNMENT)
                .fetchAll(
                    `getEndpointAssignmentsByAirline?airlineId=${values.airlineId}`
                )
                .then((res) => {
                    const assignedEPs = res.data;
                    if (assignedEPs) {
                        let assignedEPList = assignedEPs
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((item) => ({
                                id: item.endpointId,
                                label: item.name,
                                description: item.name,
                                value: item.name,
                            }));
                        setEndPointsData(assignedEPList);
                    } else {
                        setEndPointsData([]);
                    }
                })
                .catch((err) => console.log(err));
        } else {
            setEndPointsData([]);
        }

        // setselectedFlightData(selReps);
    }, [values.airlineId]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validate()) addOrEdit(values, editMode);
    };
    return (
        <GddDialog
            title={dlgTitle}
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            resetForm={resetForm}
        >
            <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
                <Grid item xs={12}>
                    <GddControls.GddTextField
                        fullWidth={true}
                        name="name"
                        variant="outlined"
                        label="Name"
                        required={true}
                        value={values.name}
                        onChange={handleInputChange}
                        error={errors.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <GddControls.GddTextField
                        fullWidth={true}
                        name="description"
                        label="Description"
                        required={false}
                        value={values.description}
                        onChange={handleInputChange}
                        error={errors.description}
                    />
                </Grid>

                <Grid item xs={12}>
                    <GddControls.GddSelect
                        fullWidth={true}
                        name="airlineId"
                        label="Airline"
                        required={true}
                        options={airlines}
                        value={values.airlineId}
                        onChange={(e) => {
                            clearSelections();

                            setTimeout(() => {
                                // setErrors({
                                //     ...errors,
                                //     aircraftSelection: "",
                                //     flightDataSelection: "",
                                //     reportDataSelection: "",
                                //     endPointSelection: "",
                                // });

                                handleInputChange(e);
                            }, 100);
                        }}
                        error={errors.airlineId}
                    />
                </Grid>

                <Grid item xs={12}>
                    <GddControls.GddMultiSelect
                        autoCRef={autoCAircrafts}
                        items={aircrafts}
                        name="aircraftSelection"
                        required
                        label="Select Aircraft"
                        placeholder="Aircraft"
                        selectAllLabel="Select All"
                        // onChange={setSelectedAircrafts}
                        // values={selectedAircrafts}
                        onChange={(vals) => {
                            let evt = {
                                target: {
                                    name: "aircraftSelection",
                                    value: [...vals],
                                },
                            };
                            handleInputChange(evt);
                        }}
                        values={values.aircraftSelection}
                        noOptionsText="No aircraft to list"
                        includeImportOption={false}
                        error={errors.aircraftSelection}
                    />
                </Grid>
                <Grid item xs={12}>
                    <GddControls.GddSingleSelect
                        autoCRef={autoCFlightData}
                        items={flightData}
                        name="flightDataSelection"
                        label="Select Flight Data"
                        placeholder="Flight Data"
                        noOptionsText="No flight data to list"
                        value={values.flightDataSelection}
                        error={errors.flightDataSelection}
                        onChange={(e, val, reason) => {
                            let evt = {
                                target: {
                                    name: "flightDataSelection",
                                    value: val,
                                },
                            };
                            let dependentVal = {
                                reportDataSelection:
                                    values["reportDataSelection"],
                                sarDataSelection:
                                    values["sarDataSelection"]
                            };
                            handleInputChange(evt, dependentVal);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <GddControls.GddSingleSelect
                        autoCRef={autoCReportData}
                        items={reportsData}
                        name="reportDataSelection"
                        label="Select Report Data"
                        placeholder="Report Data"
                        noOptionsText="No report data to list"
                        value={values.reportDataSelection}
                        error={errors.reportDataSelection}
                        onChange={(e, val, reason) => {
                            let evt = {
                                target: {
                                    name: "reportDataSelection",
                                    value: val,
                                },
                            };
                            let dependentVal = {
                                flightDataSelection:
                                    values["flightDataSelection"],
                                sarDataSelection:
                                    values["sarDataSelection"]
                            };
                            handleInputChange(evt, dependentVal);
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <GddControls.GddSingleSelect
                        autoCRef={autoCSarData}
                        items={sarData}
                        name="sarDataSelection"
                        label="Select SAR Data"
                        placeholder="SAR Data"
                        noOptionsText="No SAR data to list"
                        value={values.sarDataSelection}
                        error={errors.sarDataSelection}
                        onChange={(e, val, reason) => {
                            let evt = {
                                target: {
                                    name: "sarDataSelection",
                                    value: val,
                                },
                            };
                            let dependentVal = {
                                flightDataSelection:
                                    values["flightDataSelection"],
                                reportDataSelection:
                                    values["reportDataSelection"]
                            };
                            handleInputChange(evt, dependentVal);
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <GddControls.GddMultiSelect
                        autoCRef={autoCEndPoint}
                        items={endPointsData}
                        required
                        // getOptionDisabled={getOptionDisabled}
                        label="Select Endpoint"
                        placeholder="Endpoint"
                        selectAllLabel="Select all"
                        // onChange={setSelectedEndPointData}
                        // values={selectedEndPointData}

                        onChange={(vals) => {
                            let evt = {
                                target: {
                                    name: "endPointSelection",
                                    value: [...vals],
                                },
                            };
                            handleInputChange(evt);
                        }}
                        values={values.endPointSelection}
                        noOptionsText="No end points to list"
                        includeImportOption={false}
                        error={errors.endPointSelection}
                    />
                </Grid>
                <Grid item xs={12}>
                    <GddControls.GddSelect
                        fullWidth={true}
                        name="zipOptionId"
                        label="Zip Options"
                        required={true}
                        options={zipOptionsData}
                        value={values.zipOptionId}
                        onChange={handleInputChange}
                        error={errors.zipOptionId}
                    />
                </Grid>
            </Grid>
        </GddDialog>
    );
}

export default PackageDetails;
