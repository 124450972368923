import axios from "axios";
import { useContext } from "react";
import { AuthContext, getCookie } from "../auth/AuthContext";
import jwt_decode from "jwt-decode";
let isRefreshing = false;
let failedQueue = [];

const useAxios = () => {
    const { loggedInUser, logout, setLoggedInUser } = useContext(AuthContext);

    const BASE_URL = window.env.REACT_APP_API_BASE_URL + "/api/";

    const axiosApiInstance = axios.create();

    const ENDPOINTS = {
        AIRLINE: "airline",
        USERS: "users",
        ROLES: "roles",
        MANUFACTURERS: "manufacturer",
        AIRCRAFTTYPE: "aircrafttype",
        AIRCRAFT: "aircraft",
        FLEET: "fleet",
        ENDPOINT_CONFIGURATION: "endpointConfiguration",
        ENDPOINT_ASSIGNMENT: "endpointAssignment",
        DATAFRAMETYPES: "dataFrameType",
        DATAFRAME: "dataFrame",
        FLIGHTDATA: "deIdMap",
        SARDATA: "sarConfiguration",
        RECORDERTYPE: "recorderType",
        RECORDERFORMAT: "recorder-format",
        BLOCKMODE: "block-mode",
        RECORDERCONFIGURATION: "recorderConfiguration",
        REDACTIONTYPE: "redactionType",
        REPORTS: "reportConfiguration",
        PACKAGES: "package",
        IOSTATS: "ioStats",
        FAILURES: "failure",
        HTTPCONFIG: "httpConfiguration",
        FPSCONFIG: "ftpsConfiguration",
        SFTPCONFIG: "sftpConfiguration",
        AWSCONFIG: "s3Configuration",
        TRANSFERTYPES: "transferType",
        OPENAUTH: "oauth",
        ACCOUNT: "account",
    };

    axiosApiInstance.interceptors.request.use(
        async (config) => {
            // const access_token = loggedInUser.accessToken;
            config.headers = {
                Authorization: `Bearer ${loggedInUser.accessToken}`,
                Accept: "application/json",
                // "Content-Type": "application/x-www-form-urlencoded",
                "Content-Type": "application/json",
            };
            return config;
        },
        (error) => {
            Promise.reject(error);
        }
    );

    const processQueue = (error, token = null) => {
        failedQueue.forEach((prom) => {
            if (error) prom.reject(error);
            else prom.resolve(token);
        });
        failedQueue = [];
    };
    // Response interceptor for API calls
    axiosApiInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        function (error) {
            const originalRequest = error.config;
            if (error.response.status === 401 && !originalRequest._retry) {
                if (isRefreshing) {
                    return new Promise(function (resolve, reject) {
                        failedQueue.push({ resolve, reject });
                    })
                        .then((token) => {
                            originalRequest.headers["Authorization"] =
                                "Bearer " + token;
                            return axios(originalRequest);
                        })
                        .catch((err) => {
                            return Promise.reject(err);
                        });
                }
                originalRequest._retry = true;
                isRefreshing = true;
                return new Promise(function (resolve, reject) {
                    const tokenUrl =
                        window.env.REACT_APP_API_BASE_URL + "/oauth/token";
                    const config = {
                        headers: {
                            Authorization:
                                "BASIC Q2xpZW50MTpFRUY0N0Q5QS1EQkE5LTREMDItQjdCMC0wNEY0Mjc5QTZEMjA=",
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    };
                    const postData = new URLSearchParams();

                    const refresh_token = getCookie("refresh_token");
                    //postData.append("Refresh_token", document.cookie.split(';').find(row => row.startsWith('refresh_token=')).split('=')[1]);
                    postData.append("Refresh_token", refresh_token);
                    postData.append("grant_type", "refresh_token");
                    axios
                        .post(tokenUrl, postData, config)
                        .then((res) => {
                            let newToken = { ...res.data };
                            let decodedAccess = jwt_decode(
                                newToken.access_token
                            );
                            document.cookie = `refresh_token=${newToken.refresh_token}`;
                            originalRequest.headers["Authorization"] =
                                "Bearer " + res.data.access_token;
                            setLoggedInUser({
                                ...loggedInUser,
                                userName: decodedAccess.unique_name,
                                role: decodedAccess.role,
                                accessToken: newToken.access_token,
                                expiresIn: newToken.expires_in,
                                tokenType: newToken.token_type,
                            });
                            processQueue(null, res.data.access_token);
                            resolve(axios(originalRequest));
                        })
                        .catch((err) => {
                            processQueue(err, null);
                            logout();
                            reject(err);
                        })
                        .then(() => {
                            isRefreshing = false;
                        });
                });
            }
            return Promise.reject(error);
        }
    );

    const createApiEndPoint = (endPoint) => {
        let url = BASE_URL + endPoint + "/";
        return {
            urlEndPoint: url,
            fetchAll: (qry) => axiosApiInstance.get(url + (qry ? qry : "")),
            fetchById: (id, qry) =>
                axiosApiInstance.get(url + (qry ? qry + "/" : "") + id),
            create: (newRecord) => axiosApiInstance.post(url, newRecord),
            update: (id, updatedRecord, qry) =>
                axiosApiInstance.put(
                    url + (qry ? qry + "/" : "") + id,
                    updatedRecord
                ),
            delete: (id) => axiosApiInstance.delete(url + id),
            upload: (qry, formData, fnUploadProgress) =>
                axiosApiInstance.post(url + (qry ? qry + "/" : ""), formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                    onUploadProgress: fnUploadProgress,
                }),
            updateWithoutId: (updatedRecord, qry) =>
                axiosApiInstance.put(
                    url + (qry ? qry + "/" : ""),
                    updatedRecord
                ),
            getRaw: (qry) => axiosApiInstance.get(url + qry),
            postRaw: (qry) => axiosApiInstance.post(url + qry),
        };
    };

    return { ENDPOINTS, createApiEndPoint, axiosApiInstance };
};

export default useAxios;
