import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import GddControls from "../../../components/gdd-controls/GddControls";
import GddDialog from "../../../components/gdd-dialog/GddDialog";
import GddDialogTitle from "../../../components/gdd-dialog/GddDialogTitle";
import AirlinesIcon from "../../../icons/admin/AirlinesIcon";
import useAxios from "../../../api/useAxios";

function AirlineDetails(props) {
    const {
        open,
        setOpen,
        addOrEdit,
        values,
        setValues,
        setErrors,
        errors,
        handleInputChange,
        resetForm,
        validate,
        editMode,
        endPointParamsList,
        setEndPointParamsList,
    } = props;

    const iconType = <AirlinesIcon iconSize="medium" />;
    const dlgTitle = (
        <GddDialogTitle
            title={editMode ? "Edit Airline" : "Create New Airline"}
            titleIcon={iconType}
        />
    );

    const [uploadFile, setUploadFile] = React.useState();

    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const [endPoints, setEndPoints] = useState([]);

    useEffect(() => {
        createApiEndPoint(ENDPOINTS.ENDPOINT_CONFIGURATION)
            .fetchAll("getAll")
            .then((res) => {
                const endPointData = res.data;
                let endPointOptions = endPointData
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((item) => ({
                        id: item.id,
                        label: item.name,
                        value: item.name,
                        description: item.name,
                    }));
                setEndPoints(endPointOptions);
            })
            .catch((err) => console.log(err));
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            let endPointParam = endPointParamsList.map((item) => ({
                id: item.id,
                description: item.name,
            }));

            values.endPoints = endPointParam;

            setUploadFile(null);
            addOrEdit(values, editMode);
        }
    };

    return (
        <div>
            <GddDialog
                title={dlgTitle}
                open={open}
                setOpen={setOpen}
                handleSubmit={handleSubmit}
                resetForm={resetForm}
            >
                <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Grid item xs={6}>
                        <GddControls.GddTextField
                            fullWidth={true}
                            name="code"
                            variant="outlined"
                            label="Code"
                            required={true}
                            inputProps={{ maxLength: 3 }}
                            value={values.code}
                            onChange={handleInputChange}
                            error={errors.code}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <GddControls.GddTextField
                            fullWidth={true}
                            name="name"
                            label="Name"
                            required={true}
                            value={values.name}
                            onChange={handleInputChange}
                            error={errors.name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GddControls.GddMultiSelect
                            items={endPoints}
                            label="Select Endpoints"
                            placeholder="Endpoints"
                            selectAllLabel="Select all"
                            onChange={setEndPointParamsList}
                            values={endPointParamsList}
                            noOptionsText="No Endpoints to list"
                            includeImportOption={false}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <GddControls.GddCheckbox
                            fullWidth={true}
                            name="isEnabled"
                            label="Enabled"
                            value={values.isEnabled}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <GddControls.GddCheckbox
                            fullWidth={true}
                            name="isEnabledRawDataExport"
                            label="Enable Raw Data Export"
                            value={values.isEnabledRawDataExport}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <GddControls.GddCheckbox
                            fullWidth={true}
                            name="isEnabledEngineeringUnitExport"
                            label="Enable Engineering Unit Export"
                            value={values.isEnabledEngineeringUnitExport}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <GddControls.GddCheckbox
                            fullWidth={true}
                            name="isEnabledAcmsReportExport"
                            label="Enable ACMS Report Export"
                            value={values.isEnabledAcmsReportExport}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <GddControls.GddCheckbox
                            fullWidth={true}
                            name="isEnabledSarDataExport"
                            label="Enable SAR Data Export"
                            value={values.isEnabledSarDataExport}
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>
            </GddDialog>
        </div>
    );
}

export default AirlineDetails;
