import GddTextField from "./GddTextField";
import GddButton from "./GddButton";
import GddCheckbox from "./GddCheckbox";
import GddCheckboxTriState from "./GddCheckboxTriState"
import GddRadioGroup from "./GddRadioGroup";
import GddSelect from "./GddSelect";
import GddActionButton from "./GddActionButton";
import GddTransferList from "./GddTransferList";
import GddCustomList from "./GddCustomList";
import GddSearchList from "./GddSearchList";
import GddTransferTable from "./GddTransferTable";
import GddFileUpload from "./GddFileUpload";
import GddSnackbar from "./GddSnackbar";
import GddMultiSelect from "./GddMultiSelect";
import GddSingleSelect from "./GddSingleSelect";
import GddLoadingButton from "./GddLoadingButton";
import GddAlert from "./GddAlert";

const GddControls = {
    GddTextField,
    GddButton,
    GddCheckbox,
    GddCheckboxTriState,
    GddRadioGroup,
    GddSelect,
    GddActionButton,
    GddCustomList,
    GddSearchList,
    GddTransferList,
    GddTransferTable,
    GddFileUpload,
    GddSnackbar,
    GddMultiSelect,
    GddSingleSelect,
    GddLoadingButton,
    GddAlert,
};

export default GddControls;
