import React, { useContext } from "react";

import {
    Box,
    Container,
    CssBaseline,
    Grid,
    Paper,
    Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import logoImage from "../../images/logo.png";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import GddAccountMenu from "../gdd-account-menu/GddAccountMenu";
import LoginPage from "../../pages/login/LoginPage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import Sidebar from "../sidenav/Sidebar";
import { ProtectedRoute } from "../ProtectedRoute";
import AirlinesPage from "../../pages/admin/airlines/AirlinesPage";
import AircraftsPage from "../../pages/aircrafts/AircraftsPage";
import FleetsPage from "../../pages/fleets/FleetsPage";
import DataFramesPage from "../../pages/datafranes/DataFramesPage";
import FlightDataPage from "../../pages/flightdata/FlightDataPage";
import SarDataPage from "../../pages/sardata/SarDataPage";
import ReportsPage from "../../pages/reports/ReportsPage";
import EndPointsPage from "../../pages/endpoints/EndPointsPage";
import PackagesPage from "../../pages/packages/PackagesPage";
import IOStatsPage from "../../pages/stats/IOStatsPage";
import FailuresPage from "../../pages/stats/FailuresPage";
import UsersPage from "../../pages/admin/users/UsersPage";
import ManufacturersPage from "../../pages/admin/airframers/AirframersPage";
import AircraftTypesPage from "../../pages/admin/aircraftTypes/AircraftTypes";
import { AuthContext } from "../../auth/AuthContext";
import RecorderConfigsPage from "../../pages/recorderconfigs/RecorderConfigsPage";
import AdminHomePage from "../../pages/admin/home/AdminHomePage";
import MgrHomePage from "../../pages/mgrhome/MgrHomePage";
import OAuthPage from "../../pages/admin/oAuth/OAuthPage";
import EmailConfirmation from "../../pages/email/EmailConfirmation";
import ForgotPassword from "../../pages/login/ForgotPassword";

function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href="www.teledynecontrols.com">
                Teledyne Controls
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

// const widthOfDrawer = isLoggedIn() ? 260 : 0;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, drawerWidth }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, drawerWidth }) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

const mdTheme = createTheme();

function DashboardContent({ history }) {
    const drawerWidth = 260;
    const [open, setOpen] = React.useState(true);
    const { loggedInUser } = useContext(AuthContext);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <Router>
            <ThemeProvider theme={mdTheme}>
                <Box sx={{ display: "flex" }}>
                    <CssBaseline />
                    <AppBar
                        position="absolute"
                        open={open}
                        drawerWidth={loggedInUser ? drawerWidth : 0}
                    >
                        <Toolbar
                            sx={{
                                pr: "24px", // keep right padding when drawer
                            }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleDrawer}
                                sx={{
                                    marginRight: "36px",
                                    ...(open && { display: "none" }),
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                sx={{ flexGrow: 1 }}
                            >
                                GDD - Control and Configuration
                            </Typography>

                            <Typography
                                component="h4"
                                variant="subtitle2"
                                color="inherit"
                                noWrap
                                sx={{ flexGrow: 1 }}
                            >
                                {loggedInUser
                                    ? "You are logged in as: " +
                                      loggedInUser.userName
                                    : ""}
                            </Typography>
                            <img src={logoImage} alt="TDY logo" height="30px" />
                            {loggedInUser ? (
                                <GddAccountMenu uid={loggedInUser.userName} />
                            ) : null}
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        variant="permanent"
                        open={open}
                        drawerWidth={loggedInUser ? drawerWidth : 0}
                    >
                        <Toolbar
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                px: [1],
                            }}
                        >
                            <IconButton onClick={toggleDrawer}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </Toolbar>
                        <Divider sx={{ marginTop: 1.2 }} />
                        {/* {<List>{mainListItems}</List>} */}
                        {loggedInUser ? (
                            <Sidebar showToolTip={open ? false : true} />
                        ) : null}
                    </Drawer>

                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === "light"
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            minHeight: "100vh",

                            overflow: "auto",
                        }}
                    >
                        <Toolbar />
                        <Container
                            maxWidth="false"
                            sx={{
                                mt: 4,
                                mb: 4,
                            }}
                        >
                            <Grid spacing={3}>
                                <Grid item xs={12} md={12} lg={12}>
                                    {/* <Paper
                                        variant="elevation"
                                        elevation={10}
                                        sx={{
                                            p: 0,
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    > */}
                                    <Switch>
                                        <Route exact path="/">
                                            <LoginPage
                                                style={{
                                                    margin: "20px auto",
                                                }}
                                            />
                                        </Route>
                                        <Route
                                            exact
                                            path="/account/confirmEmail"
                                        >
                                            <EmailConfirmation
                                                style={{
                                                    margin: "20px auto",
                                                }}
                                            />
                                        </Route>
                                        <Route
                                            exact
                                            path="/account/forgotPassword"
                                        >
                                            <ForgotPassword
                                                style={{
                                                    margin: "20px auto",
                                                }}
                                            />
                                        </Route>
                                        <ProtectedRoute
                                            exact
                                            path="/home"
                                            // component={AdminHomePage}
                                            component={
                                                loggedInUser &&
                                                loggedInUser.role.toLowerCase() ===
                                                    "admin"
                                                    ? AdminHomePage
                                                    : MgrHomePage
                                            }
                                        />
                                        <ProtectedRoute
                                            exact
                                            path="/airlines"
                                            component={AirlinesPage}
                                        />
                                        <ProtectedRoute
                                            exact
                                            path="/users"
                                            component={UsersPage}
                                        />
                                        <ProtectedRoute
                                            exact
                                            path="/airframers"
                                            component={ManufacturersPage}
                                        />

                                        <ProtectedRoute
                                            exact
                                            path="/aircrafttypes"
                                            component={AircraftTypesPage}
                                        />

                                        <ProtectedRoute
                                            exact
                                            path="/endpointsconfig"
                                            component={EndPointsPage}
                                        />

                                        <ProtectedRoute
                                            exact
                                            path="/oAuth"
                                            component={OAuthPage}
                                        />

                                        <ProtectedRoute
                                            exact
                                            path="/aircraft"
                                            component={AircraftsPage}
                                        />
                                        <ProtectedRoute
                                            exact
                                            path="/recorderconfigs"
                                            component={RecorderConfigsPage}
                                        />
                                        <ProtectedRoute
                                            exact
                                            path="/fleets"
                                            component={FleetsPage}
                                        />

                                        <ProtectedRoute
                                            exact
                                            path="/dataframes"
                                            component={DataFramesPage}
                                        />

                                        <ProtectedRoute
                                            exact
                                            path="/flightdata"
                                            component={FlightDataPage}
                                        />

                                        <ProtectedRoute
                                            exact
                                            path="/sardata"
                                            component={SarDataPage}
                                        />

                                        <ProtectedRoute
                                            exact
                                            path="/reports"
                                            component={ReportsPage}
                                        />
                                        <ProtectedRoute
                                            exact
                                            path="/packages"
                                            component={PackagesPage}
                                        />
                                        <ProtectedRoute
                                            exact
                                            path="/iostats"
                                            component={IOStatsPage}
                                        />
                                        <ProtectedRoute
                                            exact
                                            path="/failures"
                                            component={FailuresPage}
                                        />

                                        <ProtectedRoute
                                            exact
                                            path="*"
                                            component={LoginPage}
                                        />
                                    </Switch>
                                    {/* </Paper> */}
                                    <Copyright sx={{ pt: 4 }} />
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </Box>
            </ThemeProvider>
        </Router>
    );
}

function NewDashboard() {
    return <DashboardContent />;
}

export default NewDashboard;
