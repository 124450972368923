import {  Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import AircraftIcon from "../../icons/aircraft-info/AircraftIcon";
import GddControls from "../../components/gdd-controls/GddControls";
import GddDialog from "../../components/gdd-dialog/GddDialog";
import GddDialogTitle from "../../components/gdd-dialog/GddDialogTitle";
import useAxios from "../../api/useAxios";


function AircrafClone(props){
    const {
        open,
        setOpen,
        createClone,
        values,
        setValues,
        setErrors,
        errors,
        handleInputChange,
        resetForm,
        validate,        
    } = props;

    const iconType = <AircraftIcon iconSize="medium" />;
    const dlgTitle = (
        <GddDialogTitle
        title="Clone Aircraft"
        titleIcon={iconType}
        />
    );

    const handleSubmit = (e) => {
        
        e.preventDefault();
        if (validate()) {            
            createClone(values);
        }
    };


    return (
        <GddDialog 
            title={dlgTitle}
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            resetForm={resetForm}
        >
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >

               <Grid item xs={12}>
                    <GddControls.GddTextField
                        fullWidth={true}
                        name="incrementedRegistration"
                        variant="outlined"
                        label="Starting Registration"
                        required={true}
                        value={values.incrementedRegistration}
                        onChange={handleInputChange}
                        error={errors.incrementedRegistration}
                    />
                </Grid>

                <Grid item xs={12}>
                    <GddControls.GddTextField
                        fullWidth={true}
                        name="numberOfAircrafts"
                        variant="outlined"
                        label="Number of Aircraft"
                        required={true}
                        value={values.numberOfAircrafts}
                        onChange={handleInputChange}
                        error={errors.numberOfAircrafts}
                    />
                </Grid>

            </Grid>
        </GddDialog>

    );

}

export default AircrafClone;