import React from "react";
import { Checkbox, FormControl, FormControlLabel } from "@mui/material";


function GddCheckboxTriState(props) {
    const { name, label, value, disabled = false, onChange } = props;

    const ValueConverter = (name, value) => ({
        target: {
            name,
            value,
        },
    });

    return (
        <FormControl>
            <FormControlLabel
                label={label}
                control={
                    <Checkbox
                        name={name}
                        color="primary"
                        checked={value}
                        disabled={disabled}
                        indeterminate={value===null}
                        onChange={(e) =>
                            onChange(ValueConverter(name, e.target.checked))
                        }
                    />
                }
            />
        </FormControl>
    );
}

export default GddCheckboxTriState;