import React, { useEffect, useState, useRef } from "react";
import GddTable from "../../components/gdd-table/GddTable";
import TableTitle from "../../components/gdd-table/TableTitle";
import AircraftIcon from "../../icons/aircraft-info/AircraftIcon";
import { useGddForm } from "../../components/gdd-form/UseGddForm";
import GddControls from "../../components/gdd-controls/GddControls";
import useAxios from "../../api/useAxios";
import { useConfirm } from "material-ui-confirm";
import AircraftDetails from "./AircraftDetails";
import AircraftClone from "./AircraftClone";

const initialFieldValues = {
    id: 0,
    registration: "",
    fleetId: "",
    dataFrameId: "",
    reportMapId: "",
    sarMapId: "",
    recorderConfigurationId1: "",
    recorderConfigurationId2: "",
    isEnabled: false,
    airlineId: "",
    incrementedRegistration: "",
    numberOfAircrafts: "",
};

function AircraftsPage() {
    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const validate = (fieldValues = values) => {
        // Update error only for the given property.
        let temp = { ...errors };
       
        if (openPopup) {
            if ("registration" in fieldValues)
                temp.registration =
                    fieldValues.registration !== ""
                        ? ""
                        : "Registration is required";

            if ("fleetId" in fieldValues)
                temp.fleetId =
                    fieldValues.fleetId !== "" ? "" : "Fleet is required";

            if ("recorderConfigurationId1" in fieldValues)
                temp.recorderConfigurationId1 =
                    fieldValues.recorderConfigurationId1 !== ""
                        ? ""
                        : "Recorder 1 is required";
        }

        if (openClonePopup) {
            if ("incrementedRegistration" in fieldValues)
                temp.incrementedRegistration =
                    fieldValues.incrementedRegistration !== ""
                        ? ""
                        : "Increment Registration is required";

            if ("numberOfAircrafts" in fieldValues)
                temp.numberOfAircrafts =
                    fieldValues.numberOfAircrafts !== ""
                        ? ""
                        : "Number of Aircraft is required";
        }
       if(!bulkEdit)
         setErrors({ ...temp });
       else{
           return true;
       }
        

        // Return the following only in case of whole form validation (not single form validation)
        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const confirm = useConfirm();

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        editMode,
        setEditMode,
    } = useGddForm(initialFieldValues, validate, true);

    const iconType = <AircraftIcon iconSize="medium" />;
    const tableTitle = <TableTitle title="Aircraft" titleIcon={iconType} />;

    const [openPopup, setopenPopup] = useState(false);
    const [openClonePopup, setOpenClonePopup] = useState(false);

    const [snackbarState, setSnackbarState] = useState({
        openSnackbar: false,
        snackbarMsg: "",
        snackbarSeverity: "success",
    });

    const [bulkEdit, setBulkEdit] = useState(false);
    const [bulkAircraftList, setBulkAircraftList] = useState([]);
    const [dataFrames, setDataFrames] = useState([]);
    const [reports, setReports] = useState([]);
    const [sars, setSars] = useState([])

    const { openSnackbar, snackbarMsg, snackbarSeverity } = snackbarState;
    const [fetchCount, setFetchCount] = useState(0);

    const tableRef = useRef();

    const [defaultFilters, setDefaultFilters] = useState({});

    const getDefaultFilter = (columnName) => {
        if (defaultFilters[columnName]) return defaultFilters[columnName];
        else return null;
    };

    const cols = [
        {
            title: "Id",
            field: "id",
            filterPlaceholder: "Filter by Id",
            hidden: true,
        },
        {
            title: "Registration",
            field: "registration",
            filterPlaceholder: "Filter by Registration",
        },
        {
            title: "Enabled",
            field: "isEnabled",           
            filterPlaceholder: "Filter by Enabled",
        },
        {
            title: "Airline",
            field: "airline",
            filterPlaceholder: "Filter by Airline",
        },
        {
            title: "Fleet",
            field: "fleet",
            filterPlaceholder: "Filter by Fleet",
        },
        {
            title: "Recorder 1",
            field: "recorderConfiguration1",
            filterPlaceholder: "Filter by Recorder 1",
        },
        {
            title: "Recorder 2",
            field: "recorderConfiguration2",
            filterPlaceholder: "Filter by Recorder 2",
        },
        {
            title: "Data Frame",
            field: "dataFrame",
            filterPlaceholder: "Filter by Data Frame",
        },
        {
            title: "Report Map",
            field: "reportMap",
            filterPlaceholder: "Filter by Report Map",
        },
        {
            title: "SAR Map",
            field: "sarMap",
            filterPlaceholder: "Filter by SAR Map",
        }
    ];

    cols.forEach((item) => (item.defaultFilter = getDefaultFilter(item.field)));

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [fetchCount]);

    const addOrEdit = (aircraft, isEditMode, bulkEdit) => {
        if (!isEditMode) {
            createAircraft(aircraft);
        } else {
            if (bulkEdit) {
                bulkAircraftsUpdate(aircraft);
            } else {
                updateAircraft(aircraft);
            }
        }
    };

    const fetchDataFrame = (fleetId) => {
        setDataFrames([]);
        setReports([]);
        setSars([]);
        values.dataFrameId = "";
        values.reportMapId = "";
        values.sarMapId = "";

        if (fleetId) {
            createApiEndPoint(ENDPOINTS.DATAFRAME)
                .fetchAll(`getDataframeByFleet?fleetId=${fleetId}`)
                .then((res) => {
                    const dataFrames = res.data;
                    let dataFramesOptions = dataFrames
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((item) => ({
                            id: item.id,
                            title: item.name,
                        }));
                    setDataFrames(dataFramesOptions);
                })
                .catch((err) => console.log(err));

            createApiEndPoint(ENDPOINTS.DATAFRAME)
                .fetchAll(`getDataframeReportByFleet?fleetId=${fleetId}`)
                .then((res) => {
                    const reports = res.data;
                    let reportsOptions = reports
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((item) => ({
                            id: item.id,
                            title: item.name,
                        }));
                    setReports(reportsOptions);
                })
                .catch((err) => console.log(err));

            createApiEndPoint(ENDPOINTS.DATAFRAME)
                .fetchAll(`getDataframeSarByFleet?fleetId=${fleetId}`)
                .then((res) => {
                    const sars = res.data;
                    let sarOptions = sars
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((item) => ({
                            id: item.id,
                            title: item.name,
                        }));
                    setSars(sarOptions);
                })
                .catch((err) => console.log(err));
        }
    };

    const createClone = (aircraft) => {
        let dataToPost = {
            aircraftId: aircraft.id,
            incrementedRegistration: aircraft.incrementedRegistration,
            numberOfAircrafts: aircraft.numberOfAircrafts,
        };

        let url =
            window.env.REACT_APP_API_BASE_URL +
            "/api/" +
            ENDPOINTS.AIRCRAFT +
            "/cloneAircrafts/";
        axiosApiInstance
            .put(url, dataToPost)
            .then((res) => {
                resetForm();
                setOpenClonePopup(false);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully cloned Aircraft",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                console.log(error);
                console.log(error.response);
                console.log(error.request);
                let msg = "Failed to clone Aircraft: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message
                        ? error.response.data.message
                        : "Failed to clone aircraft";
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const createAircraft = (aircraft) => {
        createApiEndPoint(ENDPOINTS.AIRCRAFT)
            .create(aircraft)
            .then((res) => {
                resetForm();
                setopenPopup(false);
                //setData([...data, res.data]);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully created Aircraft",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                console.log(error.response);
                let msg = "Failed to create Aircraft: ";
                if (error.response && error.response.data) {
                    if (error.response.data.exceptionMessage) {
                        msg = error.response.data.exceptionMessage;
                    } else {
                        msg = error.response.data.message;
                    }
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const updateAircraft = (aircraft) => {
        createApiEndPoint(ENDPOINTS.AIRCRAFT)
            .update(aircraft.id, aircraft)
            .then((res) => {
                resetForm();
                setopenPopup(false);
                // let updatedData = data.map(
                //     (obj) => [res.data].find((o) => o.id === obj.id) || obj
                // );
                // setData(updatedData);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully updated Aircraft",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to update Aircraft: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteAircraft = (aircraft) => {
        createApiEndPoint(ENDPOINTS.AIRCRAFT)
            .delete(aircraft.id)
            .then((res) => {
                // let updatedData = data.filter((obj) => obj.id !== airline.id);
                // setData(updatedData);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully deleted Aircraft",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to delete Aircraft: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const bulkAircraftsUpdate = (aircraft) => {
        let dataToPost = {
            aircraftIds: [...bulkAircraftList],
            aircraftData: {
                isEnabled: aircraft.isEnabled,
                fleetId: aircraft.fleetId,
                recorderConfigurationId1: aircraft.recorderConfigurationId1,
                recorderConfigurationId2: aircraft.recorderConfigurationId2,
                dataFrameId: aircraft.dataFrameId,
                reportMapId: aircraft.reportMapId,
                sarMapId: aircraft.sarMapId
            },
        };

        if(aircraft.fleetId == "" && aircraft.dataFrameId == "" && aircraft.reportMapId == "" && aircraft.sarMapId == "" && aircraft.recorderConfigurationId1 == "" && aircraft.recorderConfigurationId2 == "" && aircraft.isEnabled == null) 
        {  setSnackbarState({...snackbarState,openSnackbar:true,snackbarMsg: "one field is required",snackbarSeverity:"error"});
           return;
        }
      
        let url =
            window.env.REACT_APP_API_BASE_URL +
            "/api/" +
            ENDPOINTS.AIRCRAFT +
            "/updateMultipleAircrafts/";
        axiosApiInstance
            .post(url, dataToPost)
            .then((res) => {
                resetForm();
                setBulkAircraftList([]);
                setopenPopup(false);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully updated selected Aircraft",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to update selected Aircraft: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const addClicked = () => {
        resetForm();
        setopenPopup(true);
        setEditMode(false);
        setBulkEdit(false);
    };

    const editClicked = (selectedAircraft) => {
        resetForm();     
        createApiEndPoint(ENDPOINTS.AIRCRAFT)
            .fetchById(selectedAircraft.id)
            .then((res) => {
                const aircraft = res.data;
                setValues({
                    ...values,
                    id: selectedAircraft.id,
                    registration: aircraft.registration,
                    shortRegistration: aircraft.shortRegistration,
                    isEnabled: aircraft.isEnabled,
                    airlineId: aircraft.airlineId,
                    fleetId: aircraft.fleetId,
                    recorderConfigurationId1: aircraft.recorderConfigurationId1,
                    recorderConfigurationId2: aircraft.recorderConfigurationId2,
                    dataFrameId: aircraft.dataFrameId,
                    reportMapId: aircraft.reportMapId,
                    sarMapId: aircraft.sarMapId,
                    incrementedRegistration: aircraft.incrementedRegistration
                });
                fetchDataFrame(aircraft.fleetId);
                setEditMode(true);
                setopenPopup(true);
                setBulkEdit(false);
            })
            .catch((error) => {
                let msg = "Failed to fetch Aircraft: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteClicked = (aircraft) => {
        confirm({
            description: `Are you sure you want to delete the aircraft '${aircraft.registration}?'`,
        })
            .then(() => deleteAircraft(aircraft))
            .catch(() => {});
    };

    const editSelectedClicked = (aircraft) => {
        setBulkAircraftList(aircraft.map((aircraft) => aircraft.id));
        resetForm();
        setValues({...values,isEnabled :null})
        setopenPopup(true);
        setEditMode(true);
        setBulkEdit(true);
    };

    const cloneClicked = (selectedAircraft) => {
        resetForm();
        setValues({
            ...values,
            id: selectedAircraft.id,
            incrementedRegistration: selectedAircraft.incrementedRegistration,
            numberOfAircrafts: 1,
        });
        setEditMode(true);
        setOpenClonePopup(true);
    };

    return (
        <div>
            {/* <GddDialog title="Aircraft" open={openPopup} setOpen={setopenPopup}> */}
            <AircraftDetails
                open={openPopup}
                setOpen={setopenPopup}
                addOrEdit={addOrEdit}
                {...{
                    values,
                    setValues,
                    errors,
                    setErrors,
                    handleInputChange,
                    resetForm,
                    validate,
                    editMode,
                }}
                bulkEdit={bulkEdit}
                fetchDataFrame={fetchDataFrame}
                dataFrames={dataFrames}
                reports={reports}
                sars = {sars}
            />
            <AircraftClone
                open={openClonePopup}
                setOpen={setOpenClonePopup}
                createClone={createClone}
                {...{
                    values,
                    setValues,
                    errors,
                    setErrors,
                    handleInputChange,
                    resetForm,
                    validate,
                }}
            />
            <GddTable
                tableRef={tableRef}
                title={tableTitle}
                cols={cols}
                fetchEndPoint={ENDPOINTS.AIRCRAFT}
                saveFilters={setDefaultFilters}
                baseUrlExt="getPage"
                pagedSearchString="pagedSearch"
                showEditSelected={true}
                selectableRows={true}
                showClone={true}
                onAdd={addClicked}
                onEdit={editClicked}
                onEditSelected={editSelectedClicked}
                onDelete={deleteClicked}
                onClone={cloneClicked}
            ></GddTable>

            <GddControls.GddSnackbar
                open={openSnackbar}
                message={snackbarMsg}
                onClose={() =>
                    setSnackbarState({ ...snackbarState, openSnackbar: false })
                }
                severity={snackbarSeverity}
            />
        </div>
    );
}

export default AircraftsPage;
