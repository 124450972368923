import React, { useEffect, useState, useRef } from "react";
import GddTable from "../../../components/gdd-table/GddTable";
import TableTitle from "../../../components/gdd-table/TableTitle";
import UserIcon from "../../../icons/admin/UsersIcon";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import UserDetails from "./UserDetails";
import GddControls from "../../../components/gdd-controls/GddControls";
import { useGddForm } from "../../../components/gdd-form/UseGddForm";
import { useConfirm } from "material-ui-confirm";
import useAxios from "../../../api/useAxios";

const initialFieldValues = {
    id: 0,
    userName: "",
    fullName: "",
    description: "",
    email: "",
    roleId: 0,
    airlines: [],
};

function UsersPage() {
    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const validate = (fieldValues = values) => {
        // Update error only for the given property.
        let temp = { ...errors };

        if ("userName" in fieldValues)
            temp.userName =
                fieldValues.userName !== "" ? "" : "Name is required";

        if ("email" in fieldValues)
            temp.email = fieldValues.email !== "" ? "" : "Email is required";

        if (fieldValues.email)
            temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
                ? ""
                : "Email is not valid.";

        if ("roleId" in fieldValues)
            temp.roleId = fieldValues.roleId !== "" ? "" : "Role is required";

        if ("airlines" in fieldValues)
            temp.airlines =
                fieldValues.airlines.length !== 0 ? "" : "Airline is required";

        setErrors({ ...temp });

        // Return the following only in case of whole form validation (not single form validation)
        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const confirm = useConfirm();

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        editMode,
        setEditMode,
    } = useGddForm(initialFieldValues, validate, true);

    const iconType = <UserIcon iconSize="medium" />;
    const tableTitle = <TableTitle title="Users" titleIcon={iconType} />;
    const [openPopup, setopenPopup] = useState(false);
    const [snackbarState, setSnackbarState] = useState({
        openSnackbar: false,
        snackbarMsg: "",
        snackbarSeverity: "success",
    });

    const { openSnackbar, snackbarMsg, snackbarSeverity } = snackbarState;
    const [fetchCount, setFetchCount] = useState(0);

    const tableRef = useRef();

    const [defaultFilters, setDefaultFilters] = useState({});

    const getDefaultFilter = (columnName) => {
        if (defaultFilters[columnName]) return defaultFilters[columnName];
        else return null;
    };

    const cols = [
        {
            title: "Id",
            field: "id",
            filterPlaceholder: "User Id",
            hidden: true,
        },
        {
            title: "User Name",
            field: "userName",
            filterPlaceholder: "Filter by User Name",
        },
        {
            title: "Airline",
            field: "airline",
            filterPlaceholder: "Filter by Airline",
            filtering: true,
            render: (rowData) => (
                <p style={{ padding: 0, margin: 0 }}>
                    {rowData.airlines.join(", ")}
                </p>
            ),
        },
        {
            title: "Email",
            field: "email",
            filterPlaceholder: "Filter by Email",
        },
        {
            title: "Role",
            field: "role",
            filterPlaceholder: "Filter by Role",
        },
        {
            title: "Confirmed",
            field: "isConfirmed",           
            filterPlaceholder: "Filter by Confirmed",
        },
    ];

    cols.forEach((item) => (item.defaultFilter = getDefaultFilter(item.field)));

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [fetchCount]);

    const rowDataOverride = (rowData) => {
        return {
            icon: () => <EmailOutlinedIcon fontSize="inherit" />,
            tooltip: "Resend Email",
            //hidden: rowData.isConfirmed === true,
            onClick: (event, rowData) => {
                resendEmail(rowData);
            },
        };
    };

    const resendEmail = (userData) => {
        createApiEndPoint(ENDPOINTS.USERS)
            .postRaw(`${userData.id}/email/resend`)
            .then((res) => {
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully resent confirmation email",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to resend confirmation email: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const addOrEdit = (user, isEditMode) => {
        console.log(user);
        if (!isEditMode) {
            createUser(user);
        } else {
            updateUser(user);
        }
    };

    const createUser = (user) => {
        createApiEndPoint(ENDPOINTS.USERS)
            .create(user)
            .then((res) => {
                resetForm();
                setopenPopup(false);
                //setData([...data, res.data]);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully created User",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to create User: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const updateUser = (user) => {
        createApiEndPoint(ENDPOINTS.USERS)
            .updateWithoutId(user, "update")
            .then((res) => {
                resetForm();
                setopenPopup(false);
                // let updatedData = data.map(
                //     (obj) => [user].find((o) => o.id === obj.id) || obj
                // );
                // setData(updatedData);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully updated User",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to update User: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteUser = (user) => {
        createApiEndPoint(ENDPOINTS.USERS)
            .delete(user.id)
            .then((res) => {
                // let updatedData = data.filter((obj) => obj.id !== airline.id);
                // setData(updatedData);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully deleted User",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to delete User: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const addClicked = () => {
        resetForm();
        setEditMode(false);
        setopenPopup(true);
    };

    const editClicked = (selectedUser) => {
        resetForm();
        createApiEndPoint(ENDPOINTS.USERS)
            .fetchById(selectedUser.id, "edit")
            .then((res) => {
                const user = res.data;
                console.log(user);
                setValues({
                    ...values,
                    id: selectedUser.id,
                    userName: user.userName,
                    fullName: user.fullName,
                    description: user.description,
                    email: user.email,
                    roleId: user.roleId,
                    airlines: user.airlines.map((item) => ({
                        id: item.id,
                        label: item.name,
                        value: item.name,
                        description: item.name,
                    })),
                });
                setEditMode(true);
                setopenPopup(true);
            })
            .catch((error) => {
                let msg = "Failed to fetch User: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteClicked = (user) => {
        confirm({
            description: `Are you sure you want to delete the user '${user.userName}?'`,
        })
            .then(() => deleteUser(user))
            .catch(() => {});
    };

    return (
        <div>
            <UserDetails
                open={openPopup}
                setOpen={setopenPopup}
                addOrEdit={addOrEdit}
                // values={values}
                // setValues={setValues}
                // setErrors={setErrors}
                // errors={errors}
                // handleInputChange={handleInputChange}
                // resetForm={resetForm}
                // validate={validate}
                {...{
                    values,
                    setValues,
                    errors,
                    setErrors,
                    handleInputChange,
                    resetForm,
                    validate,
                    editMode,
                }}
            />
            <GddTable
                tableRef={tableRef}
                title={tableTitle}
                cols={cols}
                fetchEndPoint={ENDPOINTS.USERS}
                saveFilters={setDefaultFilters}
                baseUrlExt="page"
                pagedSearchString="search"
                onAdd={addClicked}
                onEdit={editClicked}
                onDelete={deleteClicked}
                options={{ exportFileName: "Users" }}
                rowDataOverride={rowDataOverride}
            ></GddTable>

            <GddControls.GddSnackbar
                open={openSnackbar}
                message={snackbarMsg}
                onClose={() =>
                    setSnackbarState({ ...snackbarState, openSnackbar: false })
                }
                severity={snackbarSeverity}
            />
        </div>
    );
}

export default UsersPage;
