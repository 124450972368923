import React, { useState, useEffect, useRef } from "react";
import GddTable from "../../components/gdd-table/GddTable";
import TableTitle from "../../components/gdd-table/TableTitle";
import FlightDataIcon from "../../icons/param-mapping/FlightDataIcon";
import SarDataDetails from "./SarDataDetails";
import GddControls from "../../components/gdd-controls/GddControls";
import { useGddForm } from "../../components/gdd-form/UseGddForm";
import { useConfirm } from "material-ui-confirm";
import useAxios from "../../api/useAxios";

const initialFieldValues = {
    id: 0,
    name: "",
    description: "",
    conversionTypeId: "",
    dataFrameId: "",
    dataFrameName: "",
    airlineId: "",
    redactedParams: [],
};

function SarDataPage() {
    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const validate = (fieldValues = values) => {
        // Update error only for the given property.
        let temp = { ...errors };

        if ("name" in fieldValues)
            temp.name = fieldValues.name !== "" ? "" : "Name is required";

       if ("conversionTypeId" in fieldValues)
            temp.conversionTypeId =
                fieldValues.conversionTypeId !== ""
                    ? ""
                    : "Conversion Type is required";

        if ("dataFrameId" in fieldValues)
            temp.dataFrameId =
                fieldValues.dataFrameId !== "" ? "" : "Data Frame is required";

        if ("selectedChannel" in fieldValues)
            temp.selectedChannel =
                fieldValues.selectedChannel !== "" ? "" : "SAR channel is required";

        if ("redactedParams" in fieldValues)
            temp.redactedParams =
                fieldValues.redactedParams.length > 0
                    ? ""
                    : "Parameter selection is required";

        if ("airlineId" in fieldValues)
            temp.airlineId =
                fieldValues.airlineId !== "" ? "" : "Airline is required";

        setErrors({ ...temp });

        // Return the following only in case of whole form validation (not single form validation)
        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const confirm = useConfirm();

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        editMode,
        setEditMode,
    } = useGddForm(initialFieldValues, validate, true);

    const iconType = <FlightDataIcon iconSize="medium" />;
    const tableTitle = <TableTitle title="SAR Data" titleIcon={iconType} />;

    const [openPopup, setopenPopup] = useState(false);
    const [snackbarState, setSnackbarState] = useState({
        openSnackbar: false,
        snackbarMsg: "",
        snackbarSeverity: "success",
    });
    const { openSnackbar, snackbarMsg, snackbarSeverity } = snackbarState;
    const [fetchCount, setFetchCount] = useState(0);
    const [textOutShow, setTextOutShow] = useState(false);

    const handleShow = (conversionTypeId) => {
        if (conversionTypeId === 1) {
            setTextOutShow(false);
        } else setTextOutShow(true);
    };

    const tableRef = useRef();

    const [defaultFilters, setDefaultFilters] = useState({});

    const getDefaultFilter = (columnName) => {
        if (defaultFilters[columnName]) return defaultFilters[columnName];
        else return null;
    };

    const cols = [
        {
            title: "Id",
            field: "id",
            filterPlaceholder: "Filter by Id",
            hidden: true,
        },
        {
            title: "Name",
            field: "name",
            filterPlaceholder: "Filter by Name",
        },
        {
            title: "Description",
            field: "description",
            filterPlaceholder: "Filter by Description",
        },
        {
            title: "Data Frame",
            field: "dataFrame",
            filterPlaceholder: "Filter by Data Frame",
        },
        {
            title: "Conversion Type",
            field: "conversionType",
            filterPlaceholder: "Filter by Conversion Type",
        }
    ];

    cols.forEach((item) => (item.defaultFilter = getDefaultFilter(item.field)));

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [fetchCount]);

    const addOrEdit = (sarData, isEditMode) => {
        let dataToPost = {
            id: sarData.id,
            name: sarData.name,
            description: sarData.description,
            Channel: sarData.selectedChannel,
            conversionTypeId: sarData.conversionTypeId,
            parameterList: sarData.redactedParams.map((item) => ({
                mnemonic: item.label
            })),
            dataFrameId: sarData.dataFrameId
        };

        if (!isEditMode) {
            createSarData(dataToPost);
        } else {
            updateSarData(dataToPost);
        }
    };

    const createSarData = (sarData) => {
        createApiEndPoint(ENDPOINTS.SARDATA)
            .create(sarData)
            .then((res) => {
                resetForm();
                setopenPopup(false);
                //setData([...data, res.data]);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully created SAR Data",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to create SAR Data: ";
                if (error.response) {
                    msg =
                        msg +
                        "Status Code:  " +
                        error.response.status +
                        " Error: " +
                        error.response.data;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const updateSarData = (sarData) => {
        createApiEndPoint(ENDPOINTS.SARDATA)
            .update(sarData.id, sarData)
            .then((res) => {
                resetForm();
                setopenPopup(false);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully updated SAR Data",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to update SAR Data: ";
                if (error.response) {
                    msg =
                        msg +
                        "Status Code:  " +
                        error.response.status +
                        " Error: " +
                        error.response.data;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteSarData = (sarData) => {
        createApiEndPoint(ENDPOINTS.SARDATA)
            .delete(sarData.id)
            .then((res) => {
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully deleted SAR Data",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to delete SAR Data: ";
                if (error.response) {
                    msg =
                        msg +
                        "Status Code:  " +
                        error.response.status +
                        " Error: " +
                        error.response.data;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const addClicked = () => {
        resetForm();
        setEditMode(false);
        setTextOutShow(false);
        setopenPopup(true);
    };

    const editClicked = (selectedFD) => {
        resetForm();
        createApiEndPoint(ENDPOINTS.SARDATA)
            .fetchById(selectedFD.id)
            .then((res) => {
                const sarData = res.data;
                let selectedParams = [];
                if (sarData.parameterList) {
                    selectedParams = sarData.parameterList.map((item) => ({
                        id: item.mnemonic,
                        label: item.mnemonic,
                        value: item.mnemonic,
                        description: item.description,
                    }));
                }

                setValues({
                    ...values,

                    id: selectedFD.id,
                    name: sarData.name,
                    description: sarData.description,
                    selectedChannel: sarData.channel,
                    conversionTypeId: sarData.conversionTypeId,
                    dataFrameId: sarData.dataFrameId,
                    dataFrameName: sarData.dataFrame,
                    airlineId: sarData.airlineId,
                    redactedParams: selectedParams
                });
                
                if (sarData.conversionTypeId !== 1) setTextOutShow(true);
                else setTextOutShow(false);

                setEditMode(true);
                setopenPopup(true);
            })
            .catch((error) => {
                let msg = "Failed to fetch User: ";
                if (error.response) {
                    msg =
                        msg +
                        "Status Code:  " +
                        error.response.status +
                        " Error: " +
                        error.response.data;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteClicked = (sarData) => {
        confirm({
            description: `Are you sure you want to delete the SAR Data '${sarData.name}?'`,
        })
            .then(() => deleteSarData(sarData))
            .catch(() => {});
    };

    return (
        <div>
            <SarDataDetails
                open={openPopup}
                setOpen={setopenPopup}
                addOrEdit={addOrEdit}
                isAdmin={true}
                {...{
                    values,
                    setValues,
                    errors,
                    setErrors,
                    handleInputChange,
                    resetForm,
                    validate,
                    editMode,
                }}
                textOutShow={textOutShow}
                handleShow={handleShow}
            />
            <GddTable
                tableRef={tableRef}
                title={tableTitle}
                cols={cols}
                fetchEndPoint={ENDPOINTS.SARDATA}
                saveFilters={setDefaultFilters}
                baseUrlExt="getPage"
                pagedSearchString="pagedSearch"
                onAdd={addClicked}
                onEdit={editClicked}
                onDelete={deleteClicked}
                options={{ exportFileName: "SarData" }}
            ></GddTable>
            <GddControls.GddSnackbar
                open={openSnackbar}
                message={snackbarMsg}
                onClose={() =>
                    setSnackbarState({ ...snackbarState, openSnackbar: false })
                }
                severity={snackbarSeverity}
            />
        </div>
    );
}

export default SarDataPage;